import React, { Suspense, useEffect } from 'react';

import { ToastNotifier, withToast } from './contexts/toastr.context';
import { Loader } from './_components/loader.component';
import { history } from '../src/_config';

const AuthApp = React.lazy(() => import('./_components/auth-app.component'));

function AppComponent({ addToast, user }) {
    return (
        <div className="App">
            <Suspense fallback={<Loader />}>
                <AuthApp user={user} />
            </Suspense>
            <ToastNotifier />
        </div>
    );
}

const App = withToast(AppComponent);

export default App;
